import React from "react";
import * as AntdIcons from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import {
  Drawer,
  Input,
  Typography,
  Button,
  Divider,
  Select,
  Checkbox,
  message,
  Upload,
  DatePicker,
  Image,
} from "antd";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { RecordDetailsProps, FieldState, FieldType } from "models/task.model";
import { useAppDispatch, useAppState } from "redux/store";
import {
  selectActiveTask,
  selectColumns,
  selectData,
  selectSelectedTask,
} from "redux/task/selectors";
import { Column } from "redux/task/models";
import AddList from "./AddList";
import { createTask, updateTask } from "redux/task/actionCreators";
import { transformData } from "shared/utils/transformDataDynamic";
import { setDrawerVisible } from "redux/task/taskSlice";
import { BASE_API_URL } from "shared/constants/common";

const { Text } = Typography;

export function RecordDetails({ visible, onClose }: RecordDetailsProps) {
  const [recordDetailsExpanded, setRecordDetailsExpanded] = useState(true);

  const [showAllValues, setShowAllValues] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(400);
  const dragHandleRef = useRef<HTMLDivElement>(null);
  const columns = useAppState(selectColumns);
  const activeView = useAppState(selectActiveTask);
  const selectedTask = useAppState(selectSelectedTask);
  const data = useAppState(selectData);
  const dispatch = useAppDispatch();

  const [fields, setFields] = useState<{ [key: string]: FieldState }>({});

  useEffect(() => {
    const currentData = data?.find((a) => a?._id === selectedTask);
    const initialFields = columns.reduce((acc, column) => {
      if (column.dataType !== "null") {
        acc[column.id] = {
          value: currentData?.[column.id] || "",
          isEditing: false,
        };
      }
      return acc;
    }, {});
    setFields(initialFields);
  }, [selectedTask]);

  const handleFieldChange = (fieldId: string, value: string) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldId]: { ...prevFields[fieldId], value },
    }));
  };

  const handleFieldEdit = (fieldId: string, isEditing: boolean) => {
    setFields((prevFields) => ({
      ...prevFields,
      [fieldId]: { ...prevFields[fieldId], isEditing },
    }));
  };

  const handleFieldSave = async (fieldId: string) => {
    try {
      handleFieldEdit(fieldId, false);
    } catch (error) {
      console.error("Error saving field:", error);
    }
  };

  const handleButtonAction = async (fieldId: string) => {
    try {
      // Replace with your actual API call
      const response = await fetch(`/api/action/${fieldId}`, {
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("Failed to perform action");
      }

      const result = await response.json();
      message.success(result.message || "Action performed successfully");
    } catch (error) {
      console.error("Error performing action:", error);
      message.error("Failed to perform action");
    }
  };

  const renderEditableField = (column: Column, field: FieldState) => {
    if (field.isEditing) {
      switch (column.dataType) {
        case "text":
        case "url":
          return (
            <div className="flex items-center gap-2 ml-6">
              <Input
                value={field.value}
                onChange={(e) => handleFieldChange(column.id, e.target.value)}
                placeholder={`Set ${column.label}...`}
                style={inputStyle}
              />
              <Button
                type="text"
                icon={<CheckOutlined style={{ color: "#1890ff" }} />}
                onClick={() => handleFieldSave(column.id)}
                style={buttonStyle}
              />
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#8c8c8c" }} />}
                onClick={() => handleFieldEdit(column.id, false)}
                style={buttonStyle}
              />
            </div>
          );
        case "select":
          return (
            <div className="flex items-center gap-2 ml-6">
              <Select
                value={field.value}
                onChange={(value) => handleFieldChange(column.id, value)}
                style={{ width: "100%" }}
              >
                {column.options.map((option, index) => (
                  <Select.Option key={index} value={option.label}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="text"
                icon={<CheckOutlined style={{ color: "#1890ff" }} />}
                onClick={() => handleFieldSave(column.id)}
                style={buttonStyle}
              />
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#8c8c8c" }} />}
                onClick={() => handleFieldEdit(column.id, false)}
                style={buttonStyle}
              />
            </div>
          );
        case "multi-select":
          return (
            <div className="flex items-center gap-2 ml-6">
              <Select
                mode="multiple"
                value={field.value.split(",")}
                onChange={(values) =>
                  handleFieldChange(column.id, values.join(","))
                }
                style={{ width: "100%" }}
              >
                {column.options.map((option, index) => (
                  <Select.Option key={index} value={option.label || ""}>
                    {option.label || ""}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="text"
                icon={<CheckOutlined style={{ color: "#1890ff" }} />}
                onClick={() => handleFieldSave(column.id)}
                style={buttonStyle}
              />
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#8c8c8c" }} />}
                onClick={() => handleFieldEdit(column.id, false)}
                style={buttonStyle}
              />
            </div>
          );
        case "date":
          return (
            <div className="flex items-center gap-2 ml-6">
              <DatePicker
                value={field.value ? moment(field.value) : null}
                onChange={(date) =>
                  handleFieldChange(
                    column.id,
                    date ? date.format("YYYY-MM-DD") : ""
                  )
                }
                style={{ width: "100%" }}
              />
              <Button
                type="text"
                icon={<CheckOutlined style={{ color: "#1890ff" }} />}
                onClick={() => handleFieldSave(column.id)}
                style={buttonStyle}
              />
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#8c8c8c" }} />}
                onClick={() => handleFieldEdit(column.id, false)}
                style={buttonStyle}
              />
            </div>
          );
        case FieldType.Files:
          return (
            <div className="flex items-center gap-2 ml-6">
              <Upload
                name="file_upload"
                action={`${BASE_API_URL}/api/media`}
                showUploadList={true}
                className="flex justify-center  m-2"
                onChange={({ file, fileList }) => {
                  if (file.status === "done") {
                    handleFieldChange(column.id, file.response.data?.[0]?.url);
                  }
                }}
              >
                {field.value ? (
                  <img src={String(field.value) || ""} className="w-12 h-12" />
                ) : (
                  <Button icon={<UploadOutlined />}>Upload</Button>
                )}
              </Upload>
              <Button
                type="text"
                icon={<CheckOutlined style={{ color: "#1890ff" }} />}
                onClick={() => handleFieldSave(column.id)}
                style={buttonStyle}
              />
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#8c8c8c" }} />}
                onClick={() => handleFieldEdit(column.id, false)}
                style={buttonStyle}
              />
            </div>
          );
        case "checkbox":
          return (
            <div className="flex items-center gap-2 ml-6">
              <Checkbox
                checked={field.value === "true"}
                onChange={(e) =>
                  handleFieldChange(column.id, e.target.checked.toString())
                }
              >
                {column.label}
              </Checkbox>
              <Button
                type="text"
                icon={<CheckOutlined style={{ color: "#1890ff" }} />}
                onClick={() => handleFieldSave(column.id)}
                style={buttonStyle}
              />
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#8c8c8c" }} />}
                onClick={() => handleFieldEdit(column.id, false)}
                style={buttonStyle}
              />
            </div>
          );
        case "button":
          return (
            <div className="flex items-center gap-2 ml-6">
              <Button onClick={() => handleButtonAction(column.id)}>
                {column.label}
              </Button>
            </div>
          );
        default:
          return null;
      }
    }

    return (
      <div className="flex items-center ml-6">
        {field.value ? (
          column.dataType === "checkbox" ? (
            <Checkbox checked={field.value === "true"} disabled>
              {column.label}
            </Checkbox>
          ) : column.dataType === FieldType.Files ? (
            <Image src={field.value} width={80} />
          ) : column.dataType === "date" ? (
            <Text style={{ color: "#262626" }}>
              {moment(field.value).format("YYYY-MM-DD")}
            </Text>
          ) : (
            <Text style={{ color: "#262626" }}  onClick={() => handleFieldEdit(column.id, true)}>{field.value}</Text>
          )
        ) : (
          <Text style={{ color: "#8c8c8c" }}  onClick={() => handleFieldEdit(column.id, true)}>{`Set ${column.label}...`}</Text>
        )}
        {column.dataType !== "button" && (
          <Button
            type="text"
            icon={<EditOutlined style={{ color: "#8c8c8c" }} />}
            onClick={() => handleFieldEdit(column.id, true)}
            style={{ ...buttonStyle, marginLeft: 8 }}
          />
        )}
      </div>
    );
  };

  const renderDynamicFields = () => {
    return columns.map((column) => {
      if (column.dataType === "null") return null;

      const field = fields[column.id];
      if (!field) return null;

      return (
        <div key={column.id} className="mb-4">
          <div
            className="flex items-center mb-1 gap-2 text-[#8c8c8c]"
            style={{ color: "#8c8c8c" }}
          >
            {React.createElement(
              AntdIcons[column.icon] || AntdIcons["FormOutlined"]
            )}
            <Text>{column.label}</Text>
          </div>
          {renderEditableField(column, field)}
        </div>
      );
    });
  };

  const handleCreateTask = () => {
    const preData = fields;
    if (selectedTask) {
      dispatch(
        updateTask(
          transformData(
            activeView || "",
            columns.slice(0, -1),
            preData,
            selectedTask
          )
        )
      );
    } else {
      dispatch(
        createTask(
          transformData(activeView || "", columns.slice(0, -1), preData)
        )
      );
    }

    dispatch(setDrawerVisible(false));
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const newWidth = window.innerWidth - e.clientX;
        setDrawerWidth(
          Math.max(300, Math.min(newWidth, window.innerWidth - 100))
        );
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      width={isFullScreen ? "100%" : drawerWidth}
      title={null}
      getContainer={false}
      style={{
        position: "absolute",
        left: "auto",
        right: 0,
      }}
    >
      <div
        ref={dragHandleRef}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: "5px",
          cursor: "ew-resize",
        }}
        onMouseDown={() => setIsDragging(true)}
      />

      <div className="p-4 w-full">
        <div className="absolute right-10 top-3">
          <Button
            type="text"
            icon={
              isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
            }
            onClick={() => setIsFullScreen(!isFullScreen)}
            style={buttonStyle}
          />
        </div>

        <div
          className="cursor-pointer mb-4"
          onClick={() => setRecordDetailsExpanded(!recordDetailsExpanded)}
        >
          <div className="flex items-center" style={{ color: "#262626" }}></div>

          {renderDynamicFields()}

          {!showAllValues && (
            <Button
              type="text"
              onClick={() => setShowAllValues(true)}
              style={{
                ...buttonStyle,
                color: "#8c8c8c",
                paddingLeft: 24,
                height: "auto",
                marginTop: 8,
              }}
            >
              Show all values {">"}
            </Button>
          )}
        </div>

        <Divider style={{ borderColor: "#f0f0f0", margin: "24px 0" }} />
        <AddList />
      </div>
      <Button
        type="primary"
        className="flex mx-auto"
        onClick={handleCreateTask}
      >
        {" "}
        {!selectedTask ? "Create" : "Edit"} Task
      </Button>
    </Drawer>
  );
}

export const inputStyle: React.CSSProperties = {
  backgroundColor: "white",
  borderColor: "#d9d9d9",
  color: "#262626",
};

export const buttonStyle: React.CSSProperties = {
  border: "none",
  boxShadow: "none",
};

export const tagStyle: React.CSSProperties = {
  backgroundColor: "#f0f0f0",
  borderColor: "#d9d9d9",
  color: "#262626",
};
