import { FieldType } from "models/task.model";

type Row = {
  [key: string]: any;
};

type Option = {
  label: string;
  backgroundColor: string;
};

type Column = {
  id: string;
  label: string;
  icon?: string;
  accessor?: string;
  minWidth?: number;
  width?: number;
  dataType: FieldType;
  options: Option[];
  disableResizing?: boolean;
};

type TableData = {
  columns: Column[];
  data: Row[];
  skipReset: boolean;
};

interface OriginalDataItem {
  id: string;
  label: string;
  accessor: string;
  dataType: FieldType;
  icon: string;
  options: Option[];
}

export function transformDataDynamic(inputData: any[]): TableData {
  if (inputData.length === 0)
    return {
      columns: [
        {
          id: "999999",
          label: "+",
          width: 20,
          dataType: FieldType.Null,
          options: [],
          disableResizing: true,
        },
      ],
      data: [],
      skipReset: false,
    };

  console.log(inputData, "inputData");

  const columns: Column[] = Object.keys(inputData[0])
    .filter(
      (key) =>
        key !== "_id" &&
        key !== "viewId" &&
        key !== "__v" &&
        key !== "createdAt" &&
        key !== "updatedAt"
    )
    .map((key) => {
      if (
        typeof inputData[0][key] === "object" &&
        inputData[0][key]?.value !== undefined
      ) {
        const dataType = inputData[0][key]?.type;
        return {
          id: key,
          label: key.charAt(0).toUpperCase() + key.slice(1),
          accessor: key,
          dataType,
          icon: inputData[0][key]?.icon,
          options:
            dataType === FieldType.Select || dataType === FieldType.MultiSelect
              ? inputData.flatMap((item) =>
                  Array.isArray(item[key]?.value)
                    ? item[key]?.value.map((val: string) => ({
                        label: val,
                        backgroundColor: randomColor(),
                      }))
                    : [
                        {
                          label: item[key]?.value,
                          backgroundColor: randomColor(),
                        },
                      ]
                )
              : [],
        };
      }
      return {
        id: key,
        label: key,
        accessor: key,
        dataType: "null",
        options: [],
      };
    });

  columns.push({
    id: "999999",
    label: "+",
    width: 20,
    dataType: FieldType.Null,
    options: [],
    disableResizing: true,
  });

  const data: Row[] = inputData.map((item) => {
    const row: Row = {};
    Object.keys(item).forEach((key) => {
      if (key !== "viewId") {
        row[key] = item[key]?.value || "";
      }
      if (key === "_id") {
        row[key] = item[key];
      }
    });
    return row;
  });
  return { columns, data, skipReset: false };
}

export const transformData = (
  viewId: string,
  data: OriginalDataItem[],
  preData: any = {},
  id?: string
): any => {
  const transformedEntries = data.map((item) => {
    let value: null | string | string[] = "";

    // Handle select and multi-select
    if (item.dataType === "multi-select") {
      value = [];
    }

    return {
      [item.accessor]: {
        type: item.dataType === "checkbox" ? "boolean" : item.dataType,
        icon: item.icon,
        value: preData[item.accessor]?.value || value,
      },
    };
  });

  return {
    viewId,
    id,
    fromRecord: !!id,
    ...Object.assign({}, ...transformedEntries),
  };
};

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export function transformReduxData(inputData: any): { [key: string]: string } {
  const transformedData: { [key: string]: string } = {};

  for (const key in inputData) {
    if (
      inputData[key] &&
      typeof inputData[key] === "object" &&
      "value" in inputData[key]
    ) {
      transformedData[key] = inputData[key].value;
    }
  }

  return transformedData;
}
