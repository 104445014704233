import React, { Fragment } from "react";
import { Layout } from "antd";
import Header from "./header";
import SwitchView from "./SwitchView";

const { Content } = Layout;

interface NavbarProps {
  children: React.ReactNode;
  state: boolean;
  mobile_state: boolean;
  toggle: () => void;
  toggle_mobile: () => void;
  switchview: any;
  setswitchview: any;
}

const Contentbar = ({
  children,
  state,
  mobile_state,
  toggle,
  toggle_mobile,
  switchview,
  setswitchview,
}: NavbarProps) => {
  return (
    <Fragment>
      <Layout
        onClick={mobile_state ? toggle_mobile : () => null}
        className="site-layout"
        style={mobile_state ? { filter: "contrast(50%)" } : {}}
      >
        <Header
          state={state}
          toggle={toggle}
          toggle_mobile={toggle_mobile}
          switchview={switchview}
          setswitchview={setswitchview}
        />

        <Content
          className="site-layout-background"
          style={{
            minHeight: 280,
            position: "relative",
            overflowY: "auto",
          }}
        >
          <div className="hidden absolute w-full top-0 left-0 md:block z-40">
            <SwitchView
              switchview={switchview}
              setswitchview={setswitchview}
              responsive={true}
            />
          </div>
          {children}
        </Content>
      </Layout>
    </Fragment>
  );
};

export default Contentbar;
