import {
  ICreateProperties,
  ICreateView,
  IDeletePropertyRequest,
  IGetAllViewsResponse,
  IGetTasksResponse,
  IUpdateHiddenProperty,
  IUpdatePropertyRequest,
  IViewTask,
} from "models/task.model";
import { ITaskDTO, IUpdatetaskDTO } from "redux/task/models";
import { ApiResponse, httpClient } from "shared/utils";

class TaskAPI {
  static getAllViews(): ApiResponse<IGetAllViewsResponse> {
    return httpClient.get<IGetAllViewsResponse>("/view/user-views");
  }

  static getTasks(data: IViewTask): ApiResponse<IGetTasksResponse> {
    return httpClient.post<IGetTasksResponse>("/task/filter", data);
  }

  static createProperties(data: ICreateProperties): ApiResponse<any> {
    return httpClient.post<any>("/task/property/create", data);
  }

  static updateProperty(data: IUpdatePropertyRequest): ApiResponse<any> {
    return httpClient.patch<any>("/task/property/update", data);
  }

  static deleteProperty(data: IDeletePropertyRequest): ApiResponse<any> {
    return httpClient.delete<any>("/task/property/delete", { data });
  }

  static createView(data: ICreateView): ApiResponse<any> {
    return httpClient.post<any>("/view/create", data);
  }

  static createTask(data: ITaskDTO): ApiResponse<any> {
    return httpClient.post<any>("/task/create", data);
  }

  static updateTask(data: IUpdatetaskDTO): ApiResponse<any> {
    const { id, rowIndex, value, columnId, fromRecord, ...remainingData } =
      data;

    return httpClient.put<any>(`/task/update/${id}`, remainingData);
  }

  static updateHiddenProperty(data: IUpdateHiddenProperty): ApiResponse<any> {
    return httpClient.patch<any>("/view/edit-hidden-properties", data);
  }
}

export default TaskAPI;
